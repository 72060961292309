import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Our Mission</h1>
          <p>
            Our mission is to fortify and expand the commercial and economic
            connections between Türkiye and the African continent. In pursuit of
            this goal, we have launched <b>"TurkAfrica.Market"</b> as the
            foremost platform to cultivate both B2B (business-to-business) and
            B2C (business-to- consumer) relations. Central to our approach is
            the objective of linking Turkish and African enterprises via an
            innovative networking platform. We extend this initiative to connect
            businesses and consumers across the vast and burgeoning markets of
            Africa and Türkiye.
          </p>

          <p>
            Our aim is to boost opportunities for growth, collaborative
            endeavors, and mutual benefits by providing a cutting-edge e-
            commerce platform, meticulously designed to cater to the diverse and
            expanding needs of this dynamic, multilateral market.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
