import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Misyonumuz</h1>
          <p>
            Misyonumuz, Türkiye ve Afrika kıtası arasındaki ticari ve ekonomik
            bağlantıları güçlendirmek ve genişletmektir. Bu hedef doğrultusunda,
            hem B2B (işletmeden işletmeye) hem de B2C (işletmeden tüketiciye)
            ilişkileri geliştirecek önde gelen platform olarak
            <b>"TurkAfrica.Market"</b>ı başlattık. Yaklaşımımızın temelinde,
            Türk ve Afrika işletmelerini yenilikçi bir ağ platformu aracılığıyla
            bağlama hedefi bulunmaktadır. Bu girişimi, geniş ve gelişmekte olan
            Afrika ve Türkiye pazarlarındaki işletmeleri ve tüketicileri
            birbirine bağlamak için genişletiyoruz.
          </p>

          <p>
            Amacımız, büyüme fırsatlarını, işbirlikçi çabaları ve karşılıklı
            faydaları artırmak için, bu dinamik, çok taraflı pazarın çeşitli ve
            genişleyen ihtiyaçlarına özel olarak tasarlanmış, son teknoloji bir
            e-ticaret platformu sağlamaktır.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
