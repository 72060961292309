import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Notre Mission</h1>
          <p>
            Notre mission est de renforcer et d'étendre les connexions
            commerciales et économiques entre la Türkiye et le continent
            africain. Dans la poursuite de cet objectif, nous avons lancé
            <b>TürkAfrica.Market</b> comme la plateforme principale pour
            cultiver les relations B2B (business-to-business) et B2C
            (business-toconsumer). Au cœur de notre approche se trouve
            l'objectif de relier les entreprises turques et africaines via une
            plateforme de mise en réseau innovante. Nous étendons cette
            initiative pour connecter les entreprises et les consommateurs à
            travers les marchés vastes et en expansion de l'Afrique et de la
            Türkiye.
          </p>

          <p>
            Notre but est de stimuler les opportunités de croissance, les
            efforts collaboratifs et les avantages mutuels en fournissant une
            plateforme de commerce électronique de pointe, conçue minutieusement
            pour répondre aux besoins divers et croissants de ce marché
            dynamique et multilatéral.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
