import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Nossa Missão</h1>
          <p>
            Nossa missão é fortalecer e expandir as conexões comerciais e
            econômicas entre a Türkiye e o continente africano. Na busca deste
            objetivo, lançamos o <b>"TurkAfrica.Market"</b> como a principal
            plataforma para cultivar relações B2B (business-to-business) e B2C
            (business-to-consumer). Central para a nossa abordagem é o objetivo
            de conectar empresas turcas e africanas através de uma plataforma
            inovadora de networking. Estendemos esta iniciativa para conectar
            negócios e consumidores através dos vastos e emergentes mercados da
            África e da Türkiye.
          </p>

          <p>
            Nosso objetivo é impulsionar oportunidades para crescimento,
            empreendimentos colaborativos e benefícios mútuos, fornecendo uma
            plataforma de e-commerce de ponta, meticulosamente projetada para
            atender às necessidades diversas e em expansão deste mercado
            dinâmico e multilateral.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
