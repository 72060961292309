import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>Hakkımızda</h1>
          <p>
            <b> TürkAfrica.Market</b>, hem B2B hem de B2C alanlarında Türk ve
            Afrikalı işletmeler arasında bağlantılar kurmak konusunda
            uzmanlaşmış gelişmiş bir iş ağı merkezi olarak kendini
            göstermektedir. Bu dinamik platform, Türk şirketlerinin hızla
            büyüyen Afrika pazarına çeşitli ürün ve hizmetlerini sergilemeleri
            için hayati bir bağ olarak görev yapmakta ve bunun tersi de
            geçerlidir.
          </p>

          <p>
            Aynı zamanda, Afrika tüketicilerine Türkiye'den kaynaklanan yüksek
            kaliteli, ekonomik olarak uygun fiyatlı malların ve hizmetlerin
            geniş bir seçimine erişim fırsatı sunmaktadır. Bu girişim, bu iki
            bölge arasında karşılıklı ve müreffeh bir ticaret ilişkisi
            geliştirilmesinde dönüşüm noktası bir rol oynamaktadır.
          </p>
        </div>
        <div className="sec2_img_box_ta">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
