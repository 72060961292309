import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>About US</h1>
          <p>
            <b> TürkAfrica.Market</b> stands as an advanced business networking
            hub, expertly facilitating connections between Turkish and African
            enterprises across both B2B and B2C landscapes. This dynamic
            platform acts as a vital link for Turkish companies to showcase
            their diverse array of products and services. It also serves the
            rapidly expanding African market, and vice versa.
          </p>

          <p>
            Simultaneously, it provides African consumers with the opportunity
            to access a wide selection of high-quality, economically affordable
            goods and services originating from Türkiye. This initiative plays a
            pivotal role in cultivating a reciprocal and prosperous trade
            relationship between these two regions.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
