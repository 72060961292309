import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Nuestra Misión</h1>
          <p>
            Nuestra misión es fortalecer y expandir las conexiones comerciales y
            económicas entre Türkiye y el continente africano. En la búsqueda de
            este objetivo, hemos lanzado "TurkAfrica.Market" como la plataforma
            principal para cultivar relaciones tanto B2B (de empresa a empresa)
            como B2C (de empresa a consumidor). En el centro de nuestro enfoque
            está el objetivo de conectar las empresas turcas y africanas a
            través de una plataforma de networking innovadora. Extendemos esta
            iniciativa para conectar negocios y consumidores a través de los
            vastos y florecientes mercados de África y Türkiye.
          </p>

          <p>
            Nuestro objetivo es impulsar oportunidades para el crecimiento,
            esfuerzos colaborativos y beneficios mutuos al proporcionar una
            plataforma de comercio electrónico de vanguardia, meticulosamente
            diseñada para satisfacer las necesidades diversas y en expansión de
            este dinámico mercado multilateral.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
