import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <div className="sec2_textbox">
          <h1>Dhamira Yetu</h1>
          <p>
            Dhamira yetu ni kuimarisha na kupanua uhusiano wa kibiashara na
            kiuchumi kati ya Türkiye na bara la Afrika. Katika kufuatilia lengo
            hili, tumezindua "TurkAfrica.Market" kama jukwaa kuu la kukuza
            uhusiano wa B2B (biashara-kwa-biashara) na B2C (biashara-kwamteja).
            Kiini cha mbinu yetu ni lengo la kuunganisha makampuni ya Kituruki
            na ya Kiafrika kupitia jukwaa la ubunifu la mtandao. Tunapanua
            mpango huu ili kuunganisha biashara na watumiaji kupitia masoko
            makubwa na yanayochipuka ya Afrika na Türkiye.
          </p>

          <p>
            Lengo letu ni kuongeza fursa za ukuaji, juhudi za ushirikiano, na
            manufaa ya pande zote kwa kutoa jukwaa la kisasa la biashara ya
            mtandaoni, lililobuniwa kwa umakini ili kukidhi mahitaji mbalimbali
            na yanayoongezeka ya soko hili lenye nguvu na la pande nyingi.
          </p>
        </div>
        <div className="sec5_img_box">
          <img src={sec5img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
