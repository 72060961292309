import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            “TürkAfrica<span>&#174;</span> Uygulamasını” İndirin
          </h1>
          <p>Uygun Fiyatlı Yüksek Kaliteli Ürünler & Hizmetler</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            Uygulama Yakında Burada
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Hem profesyonel hem de günlük ihtiyaçlarınız için gerekli geniş bir
            ürün yelpazesi edinebileceğiniz kapsamlı bir pazar yeri keşfedin.
            İhtiyaçlarınıza özel hizmetler sunan, çeşitli alanlarda yüksek
            becerilere sahip profesyonellerle etkileşime geçin. 1,5 milyarın
            üzerinde tüketiciyle geniş bir ağa bağlanın ve dağıtımcılar,
            üreticiler, ihracatçılar, ithalatçılar, toptancılar, perakendeciler,
            lisans verenler, lisans alanlar ve yatırımcılar dahil olmak üzere iş
            dünyasının geniş bir yelpazesinde değerli ilişkiler kurun.
            <b>"TürkAfrica.Market"</b>, dinamik, küresel bir pazarda bağlantılar
            ve büyüme teşvik eden fırsatlar dünyasının kapısı olarak sizin
            hizmetinizdedir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
