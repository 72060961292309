import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Compañía Minera" },
    { id: 2, img: img3, name: "Inversor" },
    { id: 3, img: img4, name: "Productor de Café" },
    { id: 4, img: img9, name: "Importador de Café" },
    { id: 5, img: img8, name: "Minorista" },
    { id: 6, img: img11, name: "Clínica de Belleza" },
    { id: 7, img: img12, name: "Negocio Agrícola" },
    { id: 8, img: img7, name: "Repuestos Automotrices" },
    { id: 9, img: img10, name: "Empresa de Diseño Interior y Exterior" },
    { id: 10, img: img6, name: "Mayorista de Fabricación Textil" },
    { id: 11, img: img5, name: "Abogado de Negocios/Abogado Corporativo" },
    { id: 12, img: img1, name: "Fabricante de Decoración y Muebles”" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Obtén Acceso Premium</h1>
          <p>
            Conecta, cierra acuerdos y asóciate con distribuidores, fabricantes,
            exportadores, importadores, inversores, licenciatarios,
            licenciatantes, mayoristas y minoristas. Además, puedes vender tus
            productos u ofrecer tus servicios directamente a más de 1.5 mil
            millones de consumidores a través de nuestra próxima plataforma de
            comercio electrónico, TürkAfrica.Market, sin ningún costo
          </p>
          <h4>Lista de Espera de Registro Pre-Lanzamiento</h4>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Registro Gratuito aquí
          </Link>

          <div className="store_div">
            <p>Aplicación Próximamente en</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
