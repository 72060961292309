import { useState, useEffect, useRef } from "react";
import "./nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    // console.log("navbar item clicked", item);
    setIsopen(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  const Langchngae = (e) => {
    const Link = e.target.value;
    Navigate(`${Link}`);
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link to="Sec1" className="logo">
            <img src={logo} alt="" style={{ width: "100%" }} />
          </Link>
          <Link to="Sec1" className="logo2">
            <img src={logo2} alt="" style={{ width: "70%" }} />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <Link to="Sec1" onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "Ana Sayfa"
                  : lang == "/French"
                  ? "Accueil"
                  : lang == "/Portuguese"
                  ? "Início"
                  : lang == "/Swahili"
                  ? "Nyumbani"
                  : lang == "/Spanish"
                  ? "Inicio"
                  : "Home"}
              </Link>
            </li>
            <li onMouseMove={() => setonshow(true)}>
              <Link to="Sec2" offset={-100} onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "Hakkında"
                  : lang == "/French"
                  ? "À Propos"
                  : lang == "/Portuguese"
                  ? "Sobre"
                  : lang == "/Swahili"
                  ? "Kuhusu"
                  : lang == "/Spanish"
                  ? "Acerca de"
                  : "About"}
              </Link>
            </li>
            <li>
              <Link to="Sec5" offset={-100} onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "Misyon"
                  : lang == "/French"
                  ? "Mission"
                  : lang == "/Portuguese"
                  ? "Missão"
                  : lang == "/Swahili"
                  ? "Dhamira"
                  : lang == "/Spanish"
                  ? "Misión"
                  : "Mission"}
              </Link>
            </li>

            <li>
              <Link to="Contact" offset={-100} onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "İletişim"
                  : lang == "/French"
                  ? "Contact"
                  : lang == "/Portuguese"
                  ? "Contato"
                  : lang == "/Swahili"
                  ? "Wasiliana"
                  : lang == "/Spanish"
                  ? "Contacto"
                  : "Contact"}
              </Link>
            </li>

            <li>
              <Link to="NewSection" offset={-100} onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "Yatırımcılar"
                  : lang == "/French"
                  ? "Investisseurs"
                  : lang == "/Portuguese"
                  ? "Investidores"
                  : lang == "/Swahili"
                  ? "Wawekezaji"
                  : lang == "/Spanish"
                  ? "Inversionistas"
                  : "Investors"}
              </Link>
            </li>
            <button className="download_btn">
              <Link to="Sec8" offset={-100}>
                {lang == "/Turkish"
                  ? "Uygulamayı İndir"
                  : lang == "/French"
                  ? "Télécharger l'App"
                  : lang == "/Portuguese"
                  ? "Baixar App"
                  : lang == "/Swahili"
                  ? "Pakua Programu"
                  : lang == "/Spanish"
                  ? "Descargar App"
                  : "Download App"}
              </Link>
            </button>
            <select name="Lang" id="Lang" value={lang} onChange={Langchngae}>
              <option value="/">English</option>
              <option value="/Turkish">Turkish</option>
              <option value="/French">French</option>
              <option value="/Portuguese">Portuguese</option>
              <option value="/Spanish">Spanish</option>
              <option value="/Swahili">Swahili</option>
            </select>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default Navbar;
// path name :-  "/"          language name :-English
// path name :-  "/Turkish"   language name :-Turkish
// path name :-   "/French"   language name :-French
// path name :-  "/Portuguese" language name :-Portuguese
// path name :-  "/Spanish"   language name :-Spanish
// path name :-  "/Swahili"   language name :-Swahili
