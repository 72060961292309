import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>Acerca de Nosotros</h1>
          <p>
            <b> TürkAfrica.Market</b> se establece como un centro de negocios
            avanzado, facilitando conexiones expertas entre empresas turcas y
            africanas tanto en los entornos B2B como B2C. Esta plataforma
            dinámica actúa como un enlace vital para que las empresas turcas
            puedan exhibir su variada gama de productos y servicios en el
            mercado africano en rápida expansión, y viceversa.
          </p>

          <p>
            Al mismo tiempo, proporciona a los consumidores africanos la
            oportunidad de acceder a una amplia selección de bienes y servicios
            de alta calidad y económicamente asequibles provenientes de Turquía.
            Esta iniciativa juega un papel fundamental en el cultivo de una
            relación comercial recíproca y próspera entre estas dos regiones.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
