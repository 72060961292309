import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1> À Propos de Nous</h1>
          <p>
            <b> TürkAfrica.Market</b> se positionne comme un carrefour
            d'affaires avancé, facilitant avec expertise les connexions entre
            les entreprises turques et africaines dans les secteurs B2B et B2C.
            Cette plateforme dynamique sert de lien essentiel pour les
            entreprises turques afin de présenter leur large gamme de produits
            et services au marché africain en expansion rapide, et vice versa.
          </p>

          <p>
            En même temps, elle offre aux consommateurs africains l'opportunité
            d'accéder à une large sélection de biens et services de haute
            qualité et économiquement abordables, provenant de Turquie. Cette
            initiative joue un rôle pivot dans la culture d'une relation
            commerciale réciproque et prospère entre ces deux régions.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
