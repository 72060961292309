import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            Descarga la 'Aplicación TürkAfrica<span>&#174;</span>'
          </h1>
          <p>Productos y Servicios de Alta Calidad a Precios Asequibles</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            Aplicación Próximamente en
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Descubre un mercado integral donde puedes adquirir una amplia gama
            de productos esenciales tanto para tus necesidades profesionales
            como diarias. Interactúa con una diversa gama de profesionales
            altamente cualificados, cada uno ofreciendo servicios especializados
            adaptados a tus requerimientos. Conéctate con una extensa red de más
            de 1.5 mil millones de consumidores y establece relaciones valiosas
            con un amplio espectro de entidades comerciales, incluyendo
            distribuidores, fabricantes, exportadores, importadores, mayoristas,
            minoristas, licenciantes, licenciatarios e inversores.
            'TürkAfrica.Market' se erige como tu puerta de entrada a un mundo de
            oportunidades, fomentando conexiones y crecimiento en un mercado
            global dinámico.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
