import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>Sobre Nós</h1>
          <p>
            <b> TürkAfrica.Market</b> se destaca como um hub de negócios
            avançado, facilitando conexões especializadas entre empresas turcas
            e africanas, tanto em B2B quanto em B2C. Esta plataforma dinâmica
            funciona como um elo vital para as empresas turcas exporem sua vasta
            gama de produtos e serviços ao mercado africano em rápida expansão,
            e vice-versa.
          </p>

          <p>
            Paralelamente, oferece aos consumidores africanos a oportunidade de
            acessar uma seleção diversificada de bens e serviços de alta
            qualidade e com preços acessíveis, originários da Turquia. Esta
            iniciativa desempenha um papel crucial no desenvolvimento de uma
            relação comercial recíproca e próspera entre estas duas regiões.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
