import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>Kuhusu Sisi</h1>
          <p>
            <b> TürkAfrica.Market</b> inajitokeza kama kituo cha kipekee cha
            mitandao ya biashara, kikirahisisha uunganisho kati ya biashara za
            Kituruki na za Kiafrika katika mazingira ya B2B na B2C. Jukwaa hili
            lenye dynamism linatumika kama kiungo muhimu kwa makampuni ya
            Kituruki kuonyesha bidhaa na huduma zao mbalimbali kwenye soko
            linalokua kwa kasi la Afrika, na kinyume chake.
          </p>

          <p>
            Wakati huo huo, inatoa fursa kwa watumiaji wa Kiafrika kupata uteuzi
            mpana wa bidhaa na huduma zenye ubora wa juu na za kumudu kutoka
            Uturuki. Mpango huu unacheza jukumu muhimu katika kukuza uhusiano wa
            biashara wa kunufaishana kati ya mikoa hii miwili.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec2img} alt="Not found" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
