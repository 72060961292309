import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Mining Company" },
    { id: 2, img: img3, name: "Investor" },
    { id: 3, img: img4, name: "Coffee Producer" },
    { id: 4, img: img9, name: "Coffee Importer" },
    { id: 5, img: img8, name: "Retailer" },
    { id: 6, img: img11, name: "Beauty Clinic" },
    { id: 7, img: img12, name: "Agriculture Business" },
    { id: 8, img: img7, name: "Automotive Spare Parts" },
    { id: 9, img: img10, name: "Interior & Exterior Design Company" },
    { id: 10, img: img6, name: "Textile Manufacturing Wholesaler" },
    { id: 11, img: img5, name: "Business Lawyer/Corporate Attorney" },
    { id: 12, img: img1, name: "Decoration & Furniture Manufacturer" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Get Premium Access</h1>
          <p>
            Network, strike deals, and partner with distributors, manufacturers,
            exporters, importers, investors, licensors, licensees, wholesalers,
            and retailers. In addition, you can sell your products or offer your
            services directly to more than 1.5 billion consumers through our
            upcoming e-commerce platform, <b>TürkAfrica.Market</b>, at no cost.
          </p>
          <h4>Pre-Launch Registration Waiting List</h4>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Free Registration here
          </Link>

          <div className="store_div">
            <p>App Coming Soon on</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
